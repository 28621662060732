<template>
  <main class="round-post-page">
    <section>
      <div class="container py-5">
        <div class="row">
          <div class="md-down:col-12 lg:col-5 xl:col-3">
            <div style="top: 85px;">
              <mega-card class="card-round">
                <mega-drop-zone
                  class="rounded-0"
                  @change="renderFile"
                  :type="['image/jpeg', 'image/png']"
                >
                  <mega-image ratio="9x16" :src="preview" v-if="!preview" />
                  <img
                    class="w-100"
                    alt=""
                    style="transition: .1s"
                    :src="preview"
                    v-if="preview"
                    :class="{ 'opacity-0': rendering }"
                  />
                </mega-drop-zone>
              </mega-card>

              <!-- <mega-card class="card-round">
                <div class="card-body">
                  <mega-switch
                    class="w-100 mb-0 bg-success"
                    :label="$t('visible_exp')"
                    @click="
                      round['visible_in_explore'] = !round['visible_in_explore']
                    "
                    :checked="round['visible_in_explore']"
                  />
                </div>
              </mega-card> -->
              <mega-switch
                class="w-100 mt-3 bg-success"
                v-model="toTop"
                :label="$t('round_to_top')"
              ></mega-switch>
              <to-top v-show="toTop" ref="toTop" :round="idt_round"> </to-top>
              <mega-switch
                class="w-100 mt-2 bg-success"
                v-model="advertise"
                :label="$t('advert_round')"
              />
              <advertise
                v-show="advertise"
                ref="advertise"
                :round="idt_round"
              ></advertise>
              <div class="w-100" style="text-align: center">
                <mega-button
                  class="btn-success"
                  :loading="loading"
                  :disabled="!valid"
                  @click="submit"
                  >{{ $t("round_post") }}</mega-button
                >
              </div>
            </div>
          </div>

          <div class="md-down:col-12 lg:col-7 xl:col-9">
            <mega-card :title="$t('round_model')">
              <div class="card-body">
                <section-groups v-model="group" @select="changeGroup" />
              </div>

              <perfect-scrollbar class="scroll-wrap" style="max-height: 400px;">
                <div class="card-body pt-0">
                  <div class="row">
                    <div
                      class="xs:col-12 sm:col-6 md:col-4 lg:col-3 xl:col-2 py-3"
                      v-for="(model, i) in models"
                      :key="i"
                      @click="
                        round.idt_model_modif =
                          model.modifications[0]['idt_model_modif']
                      "
                      v-if="model.modifications[0]"
                    >
                      <div
                        class="p-relative border rounded cursor-pointer"
                        :style="{
                          borderColor:
                            round.idt_model_modif ===
                            model.modifications[0]['idt_model_modif']
                              ? '#028CFF'
                              : ''
                        }"
                      >
                        <mega-image
                          ratio="1x1"
                          :src="model.modifications[0]['url_icon']"
                        />
                        <mega-button
                          class="d-block btn-sm w-100 rounded-0"
                          :class="{
                            'btn-primary':
                              round.idt_model_modif ===
                              model.modifications[0]['idt_model_modif']
                          }"
                          >{{ model.name }}</mega-button
                        >
                      </div>
                    </div>
                  </div>
                  <mega-button
                    class="d-block w-100 btn-light"
                    v-if="page < pages"
                    @click="loadMore"
                    >{{ $t("load_more") }}</mega-button
                  >
                </div>
              </perfect-scrollbar>
            </mega-card>

            <mega-card :title="$t('round_opt')">
              <div class="card-body">
                <mega-select
                  :placeholder="$t('sel_user')"
                  :label="
                    `${$t('sel_user')}${
                      round.idt_user ? ' - ' + round.idt_user : ''
                    }`
                  "
                  filter
                  @search="search"
                  :options="users"
                  v-model="round.idt_user"
                  style="z-index: 200"
                />

                <mega-textarea
                  :label="$t('desc')"
                  v-model="round.message"
                  rows="3"
                  :max="2000"
                />

                <div class="row">
                  <div class="sm-down:col-12 md:col-4 lg:col-12 xl:col-4">
                    <mega-range
                      class="bg-primary mb-0"
                      :label="$t('height')"
                      v-model.number="round.height"
                      :min="10"
                      :max="3000"
                      :step="10"
                    />
                    <div class="d-flex justify-content-between text-muted">
                      <small>10cm</small>
                      <small>3000cm</small>
                    </div>

                    <mega-number
                      :min="10"
                      :max="3000"
                      :step="10"
                      v-model.number="round.height"
                    />
                  </div>

                  <div class="sm-down:col-12 md:col-4 lg:col-12 xl:col-4">
                    <mega-range
                      class="bg-primary mb-0"
                      :label="$t('round_plane_height')"
                      v-model.number="round.ref_plane_height"
                      :min="-1000"
                      :max="1000"
                      :step="0.01"
                    />
                    <div class="d-flex justify-content-between text-muted">
                      <small>-1000m</small>
                      <small>1000m</small>
                    </div>

                    <mega-number
                      :min="-1000"
                      :max="1000"
                      :step="0.01"
                      v-model.number="round.ref_plane_height"
                    />
                  </div>
                </div>
              </div>
            </mega-card>

            <map-card
              :title="$t('round_pos')"
              ratio="16x9"
              get-location
              @update="updatePosition"
              :lat="round.lat"
              :lng="round.lng"
            >
              <mega-switch
                class="mb-0 w-100"
                :label="$t('hide_loc')"
                v-model="round.is_location_hidden"
              />
              <small class="text-muted">{{ $t("hide_loc_desc") }}</small>
            </map-card>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import MapCard from "../Round_Post/components/map";
import SectionGroups from "./components/groups";
import ToTop from "../Round_Post/components/to-top";
import Advertise from "../Round_Post/components/advertise";

export default {
  name: "RoundPostPage",
  props: ["roundId"],
  data() {
    return {
      advertise: false,
      idt_round: "",
      toTop: false,
      users: [],
      models: [],
      group: undefined,
      loading: false,
      rendering: false,
      playVideo: false,
      page: 0,
      loadCount: 60,
      modelsCount: 0,
      preview: undefined,
      sliderOption: {
        spaceBetween: 30,
        slidesPerView: "auto"
      },
      round: {
        lat: 59.9598743,
        lng: 30.2965115,
        height: 100,
        ref_plane_height: 0,
        scale: 1,
        message: "",
        idt_user: undefined,
        idc_round_type: 4,
        visible_in_explore: true,
        idt_model_modif: undefined,
        preview: undefined,
        image: undefined,
        is_location_hidden: false,
        place_name: "",
        place_address: "",
        profile: {}
      }
    };
  },
  computed: {
    video_link() {
      return this.round.media
        ? this.round.media["url"]
        : this.round.videos
        ? this.round.videos[0]["url"]
        : null;
    },
    valid() {
      let required = [
        "idt_model_modif",
        "idc_round_type",
        "idt_user",
        "preview",
        "lat",
        "lng",
        "height"
      ];
      let valid = true;

      Object.keys(this.round).map(prop => {
        if (required.includes(prop) && this.round[prop] === undefined) {
          valid = false;
        }
      });

      return valid;
    },
    pages() {
      return Math.round(this.modelsCount / this.loadCount);
    }
  },
  mounted() {
    this.$navbar.name = this.$t("make_round_3d");

    this.loadModels();
    this.search();
  },
  methods: {
    search(search) {
      if (!search) {
        this.$api.v1.get("/userList").then(response => {
          this.users = [];

          response.data.users.map(user => {
            this.users.push({
              value: user.idt_user,
              name: user.name
            });
          });
        });
      } else {
        this.$api.v1.get("/userList", { params: { search } }).then(response => {
          this.users = [];

          response.data.users.map(user => {
            this.users.push({
              value: user.idt_user,
              name:
                user.name + (user.username ? " (" + user.username + ")" : "")
            });
          });
        });
      }
    },

    submit() {
      this.loading = true;

      let data = this.round;
      let formData = new FormData();

      delete data.profile;
      data["height"] = data["height"] * 0.01;
      data.sn_id = this.$store.getters.selectedSnId;

      Object.keys(data).map(prop => {
        if (this.round[prop] !== undefined)
          formData.append(prop, this.round[prop]);
      });

      this.$api.v2
        .post("/round", formData, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(response => {
          this.idt_round = response.data.round.idt_round;
          setTimeout(() => {
            if (this.toTop) {
              this.$refs.toTop.submit();
            } else {
              this.$router.push("/round/" + this.idt_round);
            }
          }, 0);
          this.loading = false;
        });
    },

    loadModels() {
      let params = {
        limit: this.loadCount,
        offset: this.loadCount * this.page,
        sn_id: this.$store.getters.selectedSnId
      };

      if (this.group > -1) params.idt_group = this.group;

      this.$api.v2.get("/models", { params }).then(response => {
        this.modelsCount = response.data.count;

        if (response.data.models)
          response.data.models.map(item => {
            if (item.modifications) this.models.push(item);
          });
      });
    },

    loadMore() {
      this.loadModels();
      this.page++;
    },

    changeGroup() {
      this.models = [];
      this.loadModels();
    },

    video_toggle() {
      if (this.playVideo) this.$refs["video"].pause();
      else this.$refs["video"].play();

      this.playVideo = !this.playVideo;
    },

    updatePosition(location) {
      this.round.place_address = location.address;
      this.round.place_name = location.name;
      this.round.lat = location.lat;
      this.round.lng = location.lng;
    },

    renderFile(file) {
      this.round.image = file;
      this.round.preview = file;
      this.rendering = true;

      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = () => {
        setTimeout(() => {
          this.preview = reader.result;
        }, 100);
        setTimeout(() => {
          this.rendering = false;
        }, 200);
      };
    }
  },
  components: {
    MapCard,
    SectionGroups,
    ToTop,
    Advertise
  }
};
</script>
